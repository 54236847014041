import { memo, type ComponentProps, type FC } from 'react'

import styled from '@emotion/styled'
import { Chip } from '@orus.eu/pharaoh'

const ContainedChip: FC<ComponentProps<typeof Chip>> = (props) => (
  <div>
    <Chip {...props} />
  </div>
)

const LoadingChip = styled(ContainedChip)`
  width: 5rem;
`

export const ChipOrganizations = memo<{
  organizations: (string | null | undefined)[]
  isLoading?: boolean
}>(function ChipUserOrganizations({ organizations: organizationsUnpruned, isLoading = false }) {
  // filters out undefined values
  const organizations = organizationsUnpruned?.filter(
    (contract): contract is NonNullable<typeof contract> => !!contract,
  )

  if (isLoading)
    return (
      <LoadingChip variant="neutral" size="large">
        {' '}
      </LoadingChip>
    )

  if (!organizations?.length || organizations.length === 0)
    return (
      <ContainedChip variant="neutral" size="large" dark={false}>
        N/A
      </ContainedChip>
    )

  if (organizations.length === 1) {
    return (
      <ContainedChip variant="neutral" size="large" dark={organizations[0] === 'orus'}>
        {organizations[0]}
      </ContainedChip>
    )
  }
  return (
    <ContainedChip variant="neutral" size="large" dark={organizations.includes('orus')}>
      2+
    </ContainedChip>
  )
})
